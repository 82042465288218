var render = function render(){var _vm=this,_c=_vm._self._c;return _c('vx-card',{staticStyle:{"padding-left":"10px"}},[_c('div',{staticClass:"data-list-container",attrs:{"id":"data-list-list-view"}},[_c('vs-table',{ref:"table",attrs:{"sst":true,"total":_vm.totalDocs,"data":_vm.users,"max-items":_vm.dataTableParams.limit,"search":"","noDataText":"You have no notifications from Fresh Clinics"},on:{"search":_vm.handleSearch,"change-page":_vm.handleChangePage,"sort":_vm.handleSort},scopedSlots:_vm._u([{key:"default",fn:function({ data }){return _vm._l((data),function(tr,indextr){return _c('vs-tr',{key:indextr,class:data[indextr].status === 'unread' ? 'unreadTr' : '',attrs:{"data":tr}},[_c('vs-td',{attrs:{"data":data[indextr].type}},[_vm._v(_vm._s(_vm._f("capitalize")(data[indextr].type)))]),_c('vs-td',{attrs:{"data":data[indextr].title}},[_vm._v(_vm._s(data[indextr].title))]),_c('vs-td',{attrs:{"data":data[indextr].message}},[_vm._v(_vm._s(data[indextr].message))]),_c('vs-td',{attrs:{"data":data[indextr].status}},[_vm._v(_vm._s(data[indextr].status))]),_c('vs-td',{attrs:{"data":data[indextr].createdAt}},[_vm._v(_vm._s(_vm._f("date_formatter")(data[indextr].createdAt)))]),_c('vs-td',{attrs:{"data":data[indextr].type}},[(
                data[indextr].type !== 'Admin Notification' &&
                  data[indextr].type !== 'Nurse Invitation'
              )?_c('vx-tooltip',{attrs:{"text":"View Details"}},[_c('vs-button',{staticClass:"m-1",staticStyle:{"float":"left"},attrs:{"type":"border","size":"small","icon-pack":"feather","icon":"icon-eye"},on:{"click":function($event){return _vm.viewDetailHandler(
                    data[indextr]._id,
                    data[indextr].type,
                    data[indextr].metaData
                  )}}})],1):_vm._e(),(
                data[indextr].type === 'Admin Notification' &&
                  data[indextr].status === 'unread'
              )?_c('vx-tooltip',{attrs:{"text":"Mark As Read"}},[_c('vs-button',{staticClass:"m-1",staticStyle:{"float":"left"},attrs:{"type":"border","size":"small","icon-pack":"feather","icon":"icon-book-open"},on:{"click":function($event){return _vm.readNotificationHandler(data[indextr]._id)}}})],1):_vm._e(),(
                data[indextr].type === 'Nurse Invitation' &&
                  data[indextr].status === 'unread'
              )?_c('vx-tooltip',{attrs:{"text":"Mark As Read"}},[_c('vs-button',{staticClass:"m-1",staticStyle:{"float":"left"},attrs:{"type":"border","size":"small","icon-pack":"feather","icon":"icon-book-open"},on:{"click":function($event){return _vm.readNotificationHandler(data[indextr]._id)}}})],1):_vm._e()],1)],1)})}}])},[_c('div',{staticClass:"flex flex-wrap-reverse flex-grow justify-between",attrs:{"slot":"header"},slot:"header"},[_c('div',{staticStyle:{"float":"left"}},[_c('vs-select',{staticClass:"mr-6",attrs:{"placeholder":"25","vs-multiple":"","autocomplete":""},model:{value:(_vm.dataTableParams.limit),callback:function ($$v) {_vm.$set(_vm.dataTableParams, "limit", $$v)},expression:"dataTableParams.limit"}},_vm._l((_vm.limitOptions),function(item,index){return _c('vs-select-item',{key:index,attrs:{"value":item.value,"text":item.text,"clearable":false}})}),1)],1)]),_c('template',{slot:"thead"},[_c('vs-th',{attrs:{"sort-key":"type"}},[_vm._v("Type")]),_c('vs-th',{attrs:{"sort-key":"title"}},[_vm._v("Title")]),_c('vs-th',{attrs:{"sort-key":"message"}},[_vm._v("Message")]),_c('vs-th',{attrs:{"sort-key":"status"}},[_vm._v("Status")]),_c('vs-th',{attrs:{"sort-key":"createdAt"}},[_vm._v("Created At")]),_c('vs-th',{attrs:{"size":"25%"}},[_vm._v("Action")])],1)],2),_c('div',{staticClass:"m-2"},[_c('span',{staticClass:"mr-2"},[_vm._v(" "+_vm._s(_vm.dataTableParams.page * _vm.dataTableParams.limit - (_vm.dataTableParams.limit - 1))+" - "+_vm._s(_vm.totalDocs - _vm.dataTableParams.page * _vm.dataTableParams.limit > 0 ? _vm.dataTableParams.page * _vm.dataTableParams.limit : _vm.totalDocs)+" of "+_vm._s(_vm.totalDocs)+" ")]),(_vm.serverResponded)?_c('div',{staticClass:"pagination-div"},[_c('paginate',{staticClass:"pagination",attrs:{"page-count":_vm.totalPage,"click-handler":_vm.handleChangePage,"page-range":9,"prevText":'<',"nextText":'>'}})],1):_vm._e()])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }