<template>
  <vx-card style="padding-left: 10px">
    <div id="data-list-list-view" class="data-list-container">
      <vs-table
        ref="table"
        :sst="true"
        :total="totalDocs"
        :data="users"
        @search="handleSearch"
        @change-page="handleChangePage"
        @sort="handleSort"
        :max-items="dataTableParams.limit"
        search
        noDataText="You have no notifications from Fresh Clinics"
      >
        <div
          slot="header"
          class="flex flex-wrap-reverse flex-grow justify-between"
        >
          <div style="float: left">
            <vs-select
              placeholder="25"
              vs-multiple
              autocomplete
              v-model="dataTableParams.limit"
              class="mr-6"
            >
              <vs-select-item
                :key="index"
                :value="item.value"
                :text="item.text"
                v-for="(item, index) in limitOptions"
                :clearable="false"
              />
            </vs-select>
          </div>
        </div>

        <template slot="thead">
          <vs-th sort-key="type">Type</vs-th>
          <vs-th sort-key="title">Title</vs-th>
          <vs-th sort-key="message">Message</vs-th>
          <vs-th sort-key="status">Status</vs-th>
          <vs-th sort-key="createdAt">Created At</vs-th>
          <vs-th size="25%">Action</vs-th>
        </template>

        <template slot-scope="{ data }">
          <vs-tr
            :class="data[indextr].status === 'unread' ? 'unreadTr' : ''"
            :data="tr"
            :key="indextr"
            v-for="(tr, indextr) in data"
          >
            <vs-td :data="data[indextr].type">{{
              data[indextr].type | capitalize
            }}</vs-td>
            <vs-td :data="data[indextr].title">{{ data[indextr].title }}</vs-td>
            <vs-td :data="data[indextr].message">{{
              data[indextr].message
            }}</vs-td>
            <vs-td :data="data[indextr].status">{{
              data[indextr].status
            }}</vs-td>
            <vs-td :data="data[indextr].createdAt">{{
              data[indextr].createdAt | date_formatter
            }}</vs-td>
            <vs-td :data="data[indextr].type">
              <vx-tooltip
                text="View Details"
                v-if="
                  data[indextr].type !== 'Admin Notification' &&
                    data[indextr].type !== 'Nurse Invitation'
                "
              >
                <vs-button
                  style="float: left;"
                  type="border"
                  size="small"
                  @click="
                    viewDetailHandler(
                      data[indextr]._id,
                      data[indextr].type,
                      data[indextr].metaData
                    )
                  "
                  icon-pack="feather"
                  icon="icon-eye"
                  class="m-1"
                ></vs-button>
              </vx-tooltip>
              <vx-tooltip
                text="Mark As Read"
                v-if="
                  data[indextr].type === 'Admin Notification' &&
                    data[indextr].status === 'unread'
                "
              >
                <vs-button
                  style="float: left;"
                  type="border"
                  size="small"
                  icon-pack="feather"
                  icon="icon-book-open"
                  @click="readNotificationHandler(data[indextr]._id)"
                  class="m-1"
                ></vs-button>
              </vx-tooltip>

              <vx-tooltip
                text="Mark As Read"
                v-if="
                  data[indextr].type === 'Nurse Invitation' &&
                    data[indextr].status === 'unread'
                "
              >
                <vs-button
                  style="float: left;"
                  type="border"
                  size="small"
                  icon-pack="feather"
                  icon="icon-book-open"
                  @click="readNotificationHandler(data[indextr]._id)"
                  class="m-1"
                ></vs-button>
              </vx-tooltip>
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
      <div class="m-2">
        <span class="mr-2">
          {{
            dataTableParams.page * dataTableParams.limit -
              (dataTableParams.limit - 1)
          }}
          -
          {{
            totalDocs - dataTableParams.page * dataTableParams.limit > 0
              ? dataTableParams.page * dataTableParams.limit
              : totalDocs
          }}
          of {{ totalDocs }}
        </span>
        <div class="pagination-div" v-if="serverResponded">
          <paginate
            :page-count="totalPage"
            :click-handler="handleChangePage"
            class="pagination"
            :page-range="9"
            :prevText="'<'"
            :nextText="'>'"
          ></paginate>
        </div>
      </div>
    </div>
  </vx-card>
</template>

<script>
import { mapActions } from "vuex";

export default {
  props: {
    canDelete: {
      type: Boolean,
      default: false
    },
    canViewDetail: {
      type: Boolean,
      default: false
    },
    viewRoute: {
      type: String,
      default: "FranchiseClinicDetail"
    }
  },
  data() {
    return {
      isMounted: false,
      totalDocs: 0,
      currentPage: 1,
      dataTableParams: {
        search: "",
        sort: "createdAt",
        dir: "desc",
        page: 1,
        limit: 25
      },
      serverResponded: false,
      users: [],
      date: new Date(),
      limitOptions: [
        { text: "5", value: 5 },
        { text: "10", value: 10 },
        { text: "25", value: 25 },
        { text: "50", value: 50 },
        { text: "100", value: 100 }
      ],
      awaitingSearch: null
    };
  },
  methods: {
    ...mapActions("nurse", ["fetchNotifications"]),
    ...mapActions("monthlyCompliance", [
      "getMonthlyCompliance",
      "startCompliance",
    ]),
    getNotificationsList() {
      let self = this;
      this.fetchNotifications(self.dataTableParams).then(res => {
        self.users = res.data.data.docs;
        self.totalDocs = res.data.data.pagination[0].total;
        self.page = res.data.data.pagination[0].page;
        self.serverResponded = true;
      });
    },
    handleSearch(searching) {
      if (this.awaitingSearch) {
        clearTimeout(this.awaitingSearch);
        this.awaitingSearch = null;
      }
      this.awaitingSearch = setTimeout(() => {
        this.serverResponded = false;
        this.dataTableParams.search = searching;
        this.dataTableParams.page = 1;
        this.$refs.table.currentx = 1;
        this.getNotificationsList();
      }, 500);
    },
    handleChangePage(page) {
      this.dataTableParams.page = page;
      this.getNotificationsList();
    },
    handleSort(key, active) {
      this.serverResponded = false;
      this.dataTableParams.sort = key;
      this.dataTableParams.dir = active;
      this.dataTableParams.page = 1;
      this.$refs.table.currentx = 1;
      this.getNotificationsList();
    },
    async viewDetailHandler(id, type, metaData) {
      const data = JSON.parse(metaData);
      if (
        type == "AHPRA Expiry Account Disabled" ||
        type == "AHPRA Expiry" ||
        type == "Indemnity Insurance Expiry Account Disabled" ||
        type == "Indemnity Insurance Expiry" ||
        type === "Updated Profile Approved" ||
        type === "Updated Profile Rejected"
      ) {
        this.$router.push("/nurse/profile");
      } else if (type == "Nurse Pending Treatment Notes") {
        this.$router.push(
          "/nurse/patients/" +
            data.patientId +
            "/treatment/" +
            data.checkInId +
            "?backRoute=nurseNotification"
        );
      } else if (type == "Treatment Outcome Updated") {
        this.$router.push(
          `/${this.$store.state.AppActiveUser.userType}/patients/${data.patientId}/treatment/${data.checkInId}?backRoute=nurseNotification`
        );
      } else if (type === "Clinic Join Request") {
        this.$router.push(
          `/${this.$store.state.AppActiveUser.userType}/clinics/${data.clinicId}/respond-clinic-join-request/${data.requestId}`
        );
      } else if (type === "New Compliance Check" || type === "Compliance Check - 7 Days remaining") {
        await this.startCompliance(data.complianceId);
        this.$router.push(
          `/org-owner/compliance/${data.complianceId}/review`
        )
      } else if (type === "Compliance Check is Due Today" || type === "Compliance Check requires changes" || type === "Compliance Check requires changes is Due Today") {
        this.$router.push(
          `/org-owner/compliance/${data.complianceId}/review`
        )
      } else if (type === "Compliance Check Failed" || type === "Compliance Check Missed") {
        this.$router.push(
          `/org-owner/compliance/`
        )
      }

      this.readNotification(id);
    },
    readNotificationHandler(notificationId) {
      this.$vs.loading();
      this.$store
        .dispatch("general/readNotifications", notificationId)
        .then(response => {
          for (var i in this.users) {
            if (this.users[i]._id == notificationId) {
              this.users[i].status = "read";
              break;
            }
          }
          this.$vs.loading.close();
        })
        .catch(err => {
          this.$vs.loading.close();
          console.log(err);
        });
    },
    readNotification(id) {
      this.$store
        .dispatch("general/readNotifications", id)
        .then(response => {})
        .catch(err => console.log(err));
    }
  },
  watch: {
    "dataTableParams.page": function(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.dataTableParams.page = newVal;
        this.getNotificationsList();
      }
    },
    "dataTableParams.limit": function(newlimit, oldLimit) {
      if (newlimit !== oldLimit) {
        this.serverResponded = false;
        this.dataTableParams.page = 1;
        this.dataTableParams.limit = newlimit;
        this.getNotificationsList();
      }
    }
  },
  computed: {
    totalPage: function() {
      return this.totalDocs / this.dataTableParams.limit >
        parseInt(this.totalDocs / this.dataTableParams.limit)
        ? parseInt(this.totalDocs / this.dataTableParams.limit) + 1
        : parseInt(this.totalDocs / this.dataTableParams.limit);
    }
  },
  created() {
    this.getNotificationsList();
  }
};
</script>

<style scoped>
tr.unreadTr {
  background: #f0f8ff;
}
</style>
